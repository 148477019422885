import { ReactElement } from "react";
import styles from "./style.module.scss";
import Form from "@components/shared/form";
import colors from "@styles/variables/lawyerconsultationVariables.module.scss";
// import dynamic from "next/dynamic";
import { useDomainContext } from "@hooks/useDomainContext";
import CombinedForm from "@components/shared/combinedForm";

// const PopupCallUs = dynamic(() => import("@components/shared/popupCallus"));

export default function ServiceForm(): ReactElement {
    const { domain } = useDomainContext();

    return domain.categories?.length === 1 ? (
        <>
            <div className={`${styles.mainBanner}`}>
                <div className={`${styles.formWrapper} py-4`}>
                    <div>
                        <Form
                            classes={{
                                formClassName: `${styles["form"]}`,
                                stepTitleClassName: styles["step-title"],
                                stepClassName: styles["step-block"],
                                stepDescriptionClassName:
                                    styles["step-description"],
                                formButtonClassName: styles["form-button"],
                                formFieldClassName: styles["form-field"],
                                formFieldLabelClassName: styles["field-label"],
                                formStepInnerClassName: styles["steps-inner"],
                                fieldsClassName: {
                                    radio: styles["radio-field"],
                                },
                                fieldsWrapperClassName:
                                    styles["fields-wrapper"],
                                tcpaClassName: styles["tcpa"],
                                labelAsTitleClassName:
                                    styles["label-as-step-title"],
                                formSubmitButtonClassName:
                                    styles["submit-button"],
                                sellingPointClassName: styles["selling-point"],
                                formProgressbarClassName:
                                    styles["custom-progress"],
                            }}
                            colors={{
                                primaryColor: colors.lcPrimary,
                                // progressBar: colors.lcProgress,
                            }}
                            showProgress={true}
                            backButton={{
                                class: styles["back-button"],
                                label: "Back",
                            }}
                            preventWindowClose
                            popupStyle={{
                                actionBtnBgColor: "#0e5aa9",
                                actionBtnColor: "#fff",
                                iconColor: "#0e5aa9",
                            }}
                            goToThankYouPage={!!domain?.defaultOffers?.length}
                            dynamicThankYouFields={["firstName"]}
                        />
                    </div>
                </div>
            </div>
        </>
    ) : (
        <CombinedForm
            formClasses={{
                // formClassName: styles["form"],
                stepTitleClassName: styles["step-title"],
                stepClassName: styles["step-block"],
                stepDescriptionClassName: styles["step-description"],
                formButtonClassName: styles["form-button"],
                formFieldClassName: styles["form-field"],
                formFieldLabelClassName: styles["field-label"],
                formStepInnerClassName: styles["steps-inner"],
                fieldsClassName: {
                    radio: styles["radio-field"],
                },
                fieldsWrapperClassName: styles["fields-wrapper"],
                tcpaClassName: styles["tcpa"],
                labelAsTitleClassName: styles["label-as-step-title"],
                formSubmitButtonClassName: styles["submit-button"],
                sellingPointClassName: styles["selling-point"],
                formProgressbarClassName: styles["custom-progress"],
            }}
            mainBannerClassName={styles.mainBanner}
            primaryColor={"#168bb5"}
            colors={{
                primaryColor: colors.primaryColor,
                progressBar: colors.primaryColor,
            }}
            backButtonClassName={styles["back-button"]}
            blockClassName={styles.serviceBlockLnk}
            blockContainerClassName={styles["block-container"]}
            question="What do you need help with today?"
            resetLeadId={true}
        />
    );
}
